<template>
  <div>
    <v-menu
      offset-x
      :disabled="disabledView"
    >
      <template #activator="{ on, attrs }">
        <v-icon
          tabindex="-1"
          class="mx-1"
          small
          dense
          v-bind="attrs"
          v-on="on"
        >
          mdi-tune
        </v-icon>
      </template>
      <v-list>
        <v-list-item
          v-for="action in actions"
          :key="action.value"
          :disabled="isDisabled(action)"
          @click="callFunction(action.value)"
        >
          {{ action.name }}
        </v-list-item>
      </v-list>
    </v-menu>
    <component
      :is="activeComponent"
      :ref="getReferenceAsync"
      :show.sync="show"
      :options="options"
    />
  </div>
</template>
<script>
import {callFunction} from "@/helpers/common";
import ConfirmAction from "@/components/shared/confirm-action.vue";
import {mapGetters} from "vuex";
import {getReferenceAsync, waitAsyncDecision, waitResultOrDie} from "@/helpers/async-component";

export default {
  components: {ConfirmAction},
  props: {
    selected: {
      type: Array,
      required: true
    },
    wareId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      activeComponent: null,
      show: false,
      asyncDecision: {
        resolve: null,
        reject: null
      },
      options: null,
      actions: [
        {
          name: "Удалить выбранные товары",
          value: "confirmAndDelete",
          requireSelected:true
        },
        {
          name: "Переместить в другую группу товаров",
          value: "selectTargetWare",
          requireSelected:true
        },
        {
          name: "Сортировать по ID",
          value: "sortById",
        },
        {
          name: "Сортировать по наименованию",
          value: "sortByDescription",
        },
        {
          name: "Сортировать по артикулу",
          value: "sortByArticle"
        },
        {
          name: "Серийный номер отсутствует",
          value: "fillWithEmptySerialNumber",
          requireSelected:true
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      requests: 'dt/getRequests',
      disabledView:'dt/getVisibility',
      selectedFields:'dtSettings/getWareDetailsFields'
    })
  },
  watch:{
    show(value){
      if(value === false) this.activeComponent = null
    }
  },
  methods: {
    callFunction,
    getReferenceAsync,
    waitAsyncDecision,
    waitResultOrDie,
    fetchWareDetails(){
      this.$emit('fetch')
    },
    isDisabled(action){
      return action?.requireSelected ? !this.selected.length : false
    },
    confirmDeletion() {
      this.activeComponent = ConfirmAction
      this.show = true
      return this.waitAsyncDecision()
    },
    deleteSelectedWares(){
      return this.$store.dispatch('dt/deleteWareDetails', {
        ids: this.selected,
        wareId: this.wareId
      })
        .then(() => this.$success("Артикулы удалены"))
        .catch(() => this.$error("Не удалось удалить артикул"))
    },
    confirmAndDelete() {
      this.confirmDeletion()
        .then(this.waitResultOrDie)
        .then(this.deleteSelectedWares)
        .then(this.fetchWareDetails)
        .catch(() => {})
    },
    showWareModal(options) {
      this.activeComponent = () => import('@/components/documents/select-ware-modal.vue')
      this.show = true
      this.options = options
      return this.waitAsyncDecision()
    },
    selectTargetWare() {
      this.showWareModal({selectMultiple: false})
        .then(this.waitResultOrDie)
        .then(this.moveToOtherWare)
        .then(this.fetchWareDetails)
        .catch(() => {})
    },
    moveToOtherWare([wareId]) {
      return this.$store.dispatch('dt/moveWareDetails', {
        wareId,
        wareDetails: this.selected
      }).then(() => this.$success("Артикул перенесен"))
        .catch(() => this.$error("Не удалось перенести артикулы"))
    },
    sortById() {
      this.sort("id")
    },
    sortByDescription() {
      this.sort("description")
    },
    sortByArticle() {
      this.sort("article")
    },
    sort(sortBy) {
      Promise.all(this.requests).then(() => this.$store.dispatch('dt/sortWareDetails', {
        wareId: this.wareId,
        sortBy
      })).then(this.fetchWareDetails)
        .then(() => this.$success("Готово"))
        .catch(() => this.$error())
    },
    fillWithEmptySerialNumber() {
      if(!this.selected.length) return this.$warning("Выберите артикулы")
      return this.$store.dispatch('dt/copyInOtherWareDetails', {
        ids:this.selected,
        values:{
          serial_numbers:"Серийный номер отсутствует"
        }
      }).then(this.fetchWareDetails)
        .then(() => {
          this.$success("Готово")
          if(!this.selectedFields.ware.includes('serial_numbers')){
            this.$store.commit('dtSettings/SET_WARE_DETAILS_FIELDS', {type: 'ware', value: [...this.selectedFields.ware, 'serial_numbers']})
          }
        })
        .catch(() => this.$error())
    },

  },
}
</script>
