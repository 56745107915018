import { render, staticRenderFns } from "./ware-details-item.vue?vue&type=template&id=318bce96&scoped=true"
import script from "./ware-details-item.vue?vue&type=script&lang=js"
export * from "./ware-details-item.vue?vue&type=script&lang=js"
import style0 from "./ware-details-item.vue?vue&type=style&index=0&id=318bce96&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318bce96",
  null
  
)

export default component.exports